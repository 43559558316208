import React from 'react';

const CodeIcon = (props) => {
    return (
        <svg width="27" height="22" viewBox="0 0 27 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
                <path d="M26.3449 10.4011L20.1946 3.88509C19.8166 3.48418 19.1856 3.4659 18.7847 3.84421C18.3844 4.22222 18.3658 4.85351 18.7442 5.25411L24.2485 11.0853L18.7442 16.9171C18.3658 17.3177 18.3844 17.9487 18.7847 18.327C18.9775 18.5088 19.2239 18.5989 19.4693 18.5989C19.7342 18.5989 19.9988 18.4939 20.1947 18.2867L26.345 11.7704C26.7076 11.3858 26.7076 10.7854 26.3449 10.4011Z" fill={props.color}/>
                <path d="M7.89436 16.9167L2.39036 11.0852L7.89436 5.25375C8.27236 4.85315 8.25408 4.22181 7.85348 3.84386C7.45319 3.46585 6.82154 3.48414 6.44358 3.88474L0.293281 10.4007C-0.0693995 10.785 -0.0693995 11.3858 0.293281 11.7701L6.44389 18.2864C6.64004 18.4942 6.90434 18.5989 7.16931 18.5989C7.41465 18.5989 7.66102 18.5085 7.85348 18.3266C8.25444 17.9486 8.27236 17.3173 7.89436 16.9167Z" fill={props.color}/>
                <path d="M14.9327 0.524701C14.3884 0.442264 13.8791 0.815906 13.796 1.36013L10.8704 20.5092C10.7873 21.0537 11.1613 21.5627 11.7059 21.6458C11.7571 21.6535 11.8076 21.6572 11.8578 21.6572C12.3422 21.6572 12.7671 21.3038 12.8425 20.8104L15.7681 1.66136C15.8512 1.11677 15.4772 0.607814 14.9327 0.524701Z" fill={props.color}/>
            </g>
            <defs>
                <clipPath id="clip0">
                <rect width="26.5957" height="21.2766" fill="white" transform="translate(0.0212402 0.446899)"/>
                </clipPath>
            </defs>
        </svg>
    )
}

export default CodeIcon;




