
import React from 'react';

const ViewIcon = (props) => {
    return (
        <svg width="35" height="22" viewBox="0 0 35 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
                <path d="M33.5743 10.4359C33.2761 10.0279 26.1704 0.446899 17.0952 0.446899C8.01998 0.446899 0.913983 10.0279 0.616085 10.4355C0.33364 10.8225 0.33364 11.3475 0.616085 11.7345C0.913983 12.1424 8.01998 21.7235 17.0952 21.7235C26.1704 21.7235 33.2761 12.1424 33.5743 11.7348C33.8572 11.3479 33.8572 10.8225 33.5743 10.4359ZM17.0952 19.5225C10.4103 19.5225 4.62053 13.1633 2.90662 11.0844C4.61831 9.00373 10.396 2.64792 17.0952 2.64792C23.7798 2.64792 29.5692 9.00594 31.2838 11.0859C29.5721 13.1666 23.7944 19.5225 17.0952 19.5225Z" fill={props.color}/>
                <path d="M17.0952 4.48218C13.4543 4.48218 10.4921 7.4444 10.4921 11.0853C10.4921 14.7262 13.4543 17.6884 17.0952 17.6884C20.7361 17.6884 23.6983 14.7262 23.6983 11.0853C23.6983 7.4444 20.7361 4.48218 17.0952 4.48218ZM17.0952 15.4873C14.6678 15.4873 12.6932 13.5126 12.6932 11.0853C12.6932 8.65796 14.6679 6.68326 17.0952 6.68326C19.5225 6.68326 21.4973 8.65796 21.4973 11.0853C21.4973 13.5126 19.5226 15.4873 17.0952 15.4873Z" fill={props.color}/>
            </g>
            <defs>
                <clipPath id="clip0">
                <rect width="33.6879" height="21.2766" fill="white" transform="translate(0.404251 0.446899)"/>
                </clipPath>
            </defs>
        </svg>
    )
}

export default ViewIcon;



